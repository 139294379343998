<template>
    <div>
        <input hidden type="text" class="form-control" id="ConfigurationId" v-model="ConfigurationId">
        <div class="modal fade" id="ConfigurationModal" tabindex="-1" aria-labelledby="ConfigurationModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ConfigurationModalLabel" class="font-weight-bold">{{ModalTitle}} Konfigurasi : {{ConfigurationName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold required">Nama Konfigurasi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="ConfigurationName" v-model="ConfigurationName" class="font-weight-bold" />
                                    <label id="errorConfigurationName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>

                            <div class="p-3 border rounded" style="width: 100%;">                                
                                <datasource ref="ConfigurationDataSource" :data="this.ConfigurationGridData" :page-size="10" :schema-model-fields="this.ConfigurationGridDataSchemaModel"/>

                                <kendo-grid ref="gridConfiguration"
                                    :data-source-ref="'ConfigurationDataSource'"
                                    :editable="{createAt: 'bottom'}"
                                    :pageable="true"
                                    :resizable="true"
                                    :filterable="true"
                                    :sortable="true"
                                    :toolbar="['create']"
                                >

                                    <kendo-grid-column  :field="'configuration_detail_name'"
                                                        :title="'Nama Detail Konfigurasi'"
                                                        :width="200"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                        
                                    <kendo-grid-column  :field="'configuration_detail_value'"
                                                        :title="'Nilai Detail Konfigurasi'"
                                                        :width="200"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                        :attributes="{ style: 'text-align:right;' }"></kendo-grid-column>
                                                        
                                    <kendo-grid-column  :title="'Action'"
                                                        :attributes="{ class: 'k-text-center' }"
                                                        :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                        :command="['destroy']"
                                                        :width="200"></kendo-grid-column>
                                </kendo-grid>
                            </div>

                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';

import configurationService from '../Script/ConfigurationServices.js';

export default {
    name: 'ConfigurationForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle: '',

            ConfigurationId:'',
            ConfigurationName:'',

            ConfigurationGridData: [],
            ConfigurationGridDataSchemaModel: {
                configuration_detail_name: { type: "string", editable: true },
                configuration_detail_value: { type: "string", editable: true },
            },
            
            DisabledInput: false,
            SaveType: '',
            Error: 0,
        }
    },
    methods: {
        // async addClick() {
        //     $(".form-error").css("display", "none");
        //     this.ModalTitle = 'Add';

        //     this.MachineTypeId = null;
        //     this.MachineTypeCode = 'Auto';
        //     this.MachineTypeName = '';
        //     this.TypeDetailGridData = [];
            
        //     this.SaveType = 'Add';
            
        //     $(".save-button").show();
        //     $(".add-button").show();
        //     window.$('#ConfigurationModal').modal('show');
        // },
        async editClick(id, View){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Edit';

            var data = await configurationService.GetConfiguration(parseInt(id));

            this.ConfigurationId = data.feature_setting_id;
            this.ConfigurationName = data.feature_name;

            this.ConfigurationGridData = [];
            if (data.feature_value != null) {
                this.ConfigurationGridData = configurationService.configValueListParseData(data.feature_value);
            }

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                $(".add-button").hide();
                this.ModalTitle = 'View';
            }
            else {
                $(".save-button").show();
                $(".add-button").show();
            }
            this.DisabledInput = true;
            window.$('#ConfigurationModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if (this.ConfigurationName == null || this.ConfigurationName == '') {
                this.errorShow('errorConfigurationName');
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                var ConfigurationGridData = this.$refs.gridConfiguration.kendoWidget().dataSource._data;
                var ConfigurationJson = configurationService.configValueDataConverter(ConfigurationGridData);

                const configData = {
                    feature_name : this.ConfigurationName,
                    feature_value : ConfigurationJson
                }

                if (this.SaveType == 'Add'){
                    // this.$loading(true);

                    // const variables = {
                    //     data : machineTypeData
                    // }

                    // machineTypeService.addQuery(variables).then(res => {
                    //     this.$loading(false);
                    //     this.$swal("Info", response.successSave, "success");
                    //     window.$('#ConfigurationModal').modal('hide');
                    //     this.$props.reload();
                    // }).catch(error => {
                    //     this.$loading(false);
                    //     var errorMessage = this.$globalfunc.errorExtractor(error)
                    //     this.$swal("Error", errorMessage, "error");
                    // })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.ConfigurationId),
                        data : configData
                    }
                    
                    configurationService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#ConfigurationModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
