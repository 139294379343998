import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class ConfigurationService {
    readQuery() {
        var query = `
            query {
                GetFeatureSetting {
                    created_at
                    last_update
                    feature_setting_id
                    feature_name
                    feature_index
                    feature_value
                }
            }
        `;
        return query;
    }
    
    async GetConfiguration (id) {
        const variables = {
            id : id,
        }
        var query = gql`
            query ($id:Int) {
                GetFeatureSetting (FeatureSettingID:$id) {
                    created_at
                    last_update
                    feature_setting_id
                    feature_name
                    feature_index
                    feature_value
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetFeatureSetting[0];
    }
    
    configValueDataConverter(data){
        const obj = {};
        data.forEach(item => {
            obj[item.configuration_detail_name] = item.configuration_detail_value;
        });
        return obj;
    }

    configValueListParseData(data){   
        var jsonData = globalfunc.destroySingleKendoProperties(data);
        var gridData = [];
        if(jsonData != null){
            var gridData = Object.keys(jsonData).map(key => ({
                configuration_detail_name: key,
                configuration_detail_value: jsonData[key]
            }));
        }     
        return gridData;
    }
    
    async editQuery(variables){
        var query = gql`
            mutation ($id:Int!, $data:NewFeatureSetting!) {
                UpdateFeatureSetting (FeatureSettingID:$id, New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new ConfigurationService();
