<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Konfigurasi</h2>
            </CCardHeader>
            <CCardBody>
                <!-- <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div> -->

                <configuration-grid ref="grid" :key="gridReload" :editClick="editClick"/>
                <configuration-form ref="ConfigurationForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import configurationService from '../Configuration/Script/ConfigurationServices.js';
import configurationGrid from '../Configuration/Grid/ConfigurationGrid.vue';
import configurationForm from '../Configuration/Component/ConfigurationForm.vue';

export default {
    name: 'Configuration',
    components: {
        'configuration-grid': configurationGrid,
        'configuration-form': configurationForm,
    },
    mounted () {
        // var permission = this.$globalfunc.permissionModule('Configuration');
        // document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        // addClick(){
        //     // this.$refs.TaxInvoiceForm.addClick();
        // },
        editClick(id, view){
            this.$refs.ConfigurationForm.editClick(id, view);
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
